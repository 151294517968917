// Login.js
import React from 'react';
import { Button, Container } from 'react-bootstrap';
import './Login.css';
import { blurbg, logo } from '../assets';

const Login = ({ onAuthenticated }) => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const GITHUB_API_BASE_URL = process.env.REACT_APP_GITHUB_API_BASE_URL;

    const loginUrl = `${API_BASE_URL}/github/login`;
    
  return (
    <div className="flex font-epilogue items-center m-[-20px]" style={{ backgroundImage: `url(${blurbg})`, backgroundSize: 'cover', height: 'calc(100vh - 1.6px)', backgroundPosition: 'center'}}>
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-[95vh] lg:py-0">
      <a href="#" class="flex no-underline items-center text-2xl font-semibold text-white">
          <img class="w-16 h-16" src={logo} alt="logo" />
          <p className="mt-[30px] font-bold text-[23px]">Workbench</p>
      </a>
      <div className="w-full rounded-lg items-center shadow border md:mt-0 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
      <div class="p-6 space-y-4 md:space-y-6 sm:p-8 items-center">
              <h1 class="text-xl text-center font-bold leading-tight tracking-tight md:text-2xl text-white">
              Welcome To Workbench
              </h1>
              <p className="text-md text-center text-white">To sign into the app, simply click the 'log in with github' button below</p>
              <ul>
                <li className="text-md text-white">💪 Create web apps in minutes not months</li>
              </ul>
    <a alt="button" href={loginUrl} className="flex hover:bg-green-700 no-underline justify-center w-[80%] text-xl mx-auto px-4 py-2 rounded-[10px] text-md font-epilogue bg-green-600 text-white">
    <svg
        className="octicon my-auto"
        viewBox="0 0 16 16"
        version="1.1"
        width="32"
        height="32"
        aria-hidden="true"
        style={{ fill: 'white', marginRight: '8px' }}
      >
        <path
          fillRule="evenodd"
          d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"
        ></path>
      </svg>
      <p className="my-auto">Login with GitHub</p>
      </a>
      </div>
      </div>
      </div>
      </div>
      
  );
};

export default Login;
