import { createCampaign, dashboard, sun, logout, tagType, payment, profile, withdraw, addSymbol } from '../assets';

export const navlinks = [
  {
    name: 'dashboard',
    imgUrl: dashboard,
    link: '/dashboard',
    disabled: false,
  },
  {
    name: 'create',
    imgUrl: addSymbol,
    link: '/create-project',
    disabled: false,
  },
  {
    name: 'credits',
    imgUrl: withdraw,
    link: '/billing',
    disabled: false,
  },
  {
    name: 'documentation',
    imgUrl: payment,
    link: '/',
    disabled: true,
  },
  {
    name: 'profile',
    imgUrl: profile,
    link: '/',
    disabled: true,
  },
  {
    name: 'logout',
    imgUrl: logout,
    link: "/logout",
  },
];