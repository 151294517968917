import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { loader } from '../assets';
import ProjectCard from './ProjectCard';

const DisplayProjects = ({ title, isLoading, accessToken, userIdProp, updateRepositories }) => {
    const navigate = useNavigate();
    const [repositories, setRepositories] = useState([]);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const GITHUB_API_BASE_URL = process.env.REACT_APP_GITHUB_API_BASE_URL;

    useEffect(() => {
      if (accessToken && !isLoading) {
        const fetchRepositories = async () => {
          try {
            const response = await axios.get(`${GITHUB_API_BASE_URL}/user/repos`, {
              headers: {
                'Authorization': `token ${accessToken}`,
              },
            });
    
            const repositoriesWithCustomTopic = response.data.filter(async (repo) => {
              const topicResponse = await axios.get(`${GITHUB_API_BASE_URL}/repos/${repo.full_name}/topics`, {
                headers: {
                  'Authorization': `token ${accessToken}`,
                  'Accept': 'application/vnd.github.mercy-preview+json',
                },
              });
              return topicResponse.data.names.includes('wb');
            });
    
            console.log('response:', response)
            const filteredRepositories = await Promise.all(repositoriesWithCustomTopic);
            console.log('response:', filteredRepositories)
            const sortedRepositories = filteredRepositories
              .filter(Boolean)
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    
            setRepositories(sortedRepositories);
          } catch (error) {
            console.error('Error fetching repositories:', error.message);
          }
        };
    
        fetchRepositories();
      }
    }, [accessToken, isLoading, updateRepositories]);
    
      
  return (
    <div>
        <h1 className="font-epilogue font-semibold text-[18px]
         text-white text-left">Dashboard</h1>
        <div className="flex flex-col">
            {isLoading && (
                <img src={loader} alt="loader" className="w-[100px] 
                h-[100px] object-contain" />
            )}

            {!isLoading && (<p className="font-epilogue 
            font-semibold text-[14px] leading-[30px] text-[#818183]">
                View & Select Projects To Work On.
            </p>)}
        </div>
        <div className="flex flex-wrap mt-[20px] gap-[26px]">
        {repositories.map((repo) => (<ProjectCard repo={repo} index={repo.id}/>))}
        <button onClick={() => navigate("/create-project")} className="font-epilogue
         hover:bg-green-900 font-semibold text-[16px] 
         leading-[26px] text-white min-h-[52px] 
         px-4 rounded-[10px] sm:w-[288px] bg-green-800 
         border-[2px] border-dashed border-green-700">
          Create New Project 
          <i class="fas fa-plus ml-2"></i></button>
        </div>
    </div>
  )
}

export default DisplayProjects