import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Outlet, Navigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Navigationbar from './components/Navigationbar';
import MyRepositories from './components/MyRepositories';
import CreateNewRepository from './components/CreateNewRepository';
import Login from './components/Login';
import Callback from './components/Callback';
import EditProject from './components/EditProject';
import Sidebar from './components/Sidebar';
import Guide from './components/Guide';
import Build from './components/Build';
import Navbar from './components/Navbar';
import CreateProject from './pages/CreateProject';
import BuildProject from './pages/BuildProject';
import PrivateRoutes from './utils/PrivateRoutes';

import { CampaignDetails, CreateCampaign, Home, Profile } from './pages';
import './App.css';

function App() {
  const [accessToken, setAccessToken] = useState(null);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const GITHUB_API_BASE_URL = process.env.REACT_APP_GITHUB_API_BASE_URL;

  const updateRepositories = () => {
    setForceUpdate(!forceUpdate);
  };

useEffect(() => {
  const token = localStorage.getItem('access_token');
  
  if (token !== null) {
    setAccessToken(token);
  }
  setLoading(false);
}, [userId]);

  const handleAccessToken = async (token) => {
    console.log('handleAccessToken token:', token)
    localStorage.setItem('access_token', token)
    try {
      const response = await fetch(`${GITHUB_API_BASE_URL}/user`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      
      setUserId(data.login);
      updateRepositories()
      
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };

  const handleSignOut = (callback) => {
    setAccessToken(null);
    localStorage.removeItem('access_token');
    callback();
  };

  const Layout = () => (
    <>
      <div className="relative: sm:-8 sm:p-4 bg-[#13131a] min-h-screen flex flex-row">
      <div className="sm:flex hidden mr-10 relative">
        <Sidebar handleSignOut={handleSignOut} />
      </div>
      <div className="flex-1 max-sm:w-full max-w-[1280px] mx-auto sm:pr-5">
      <Navbar accessToken={accessToken} userIdProp={userId} setUserIdProp={setUserId} handleSignOut={handleSignOut}/>
      <Outlet />
      </div>
      </div>
    </>
  );

  const onCreateRepository = async (name, database, serverFramework, frontendFramework, system) => {
    const response = await fetch(`${GITHUB_API_BASE_URL}/user/repos`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name }),
    });

    if (response.ok) {
    } else {
    }
  };

  return (
    <div className="relative: sm:-8 p-4 bg-[#13131a] min-h-screen flex flex-row">
      { loading ? (
    <div></div>
  ) : (
    <div className="flex-1">
      <Routes>
      <Route path="/callback" element={<Callback handleAccessToken={handleAccessToken}/>} />
        <Route element={<PrivateRoutes accessToken={accessToken}/>}>
            <Route element={<Layout />}>
            <Route path="/dashboard" element={<Home accessToken={accessToken} userIdProp={userId} updateRepositories={updateRepositories}/>} />
            <Route path="/create-project" element={<CreateProject accessToken={accessToken}/>} />
            <Route path="/build-project/:owner/:actualRepoName" element={<BuildProject accessToken={accessToken} setStatus={setStatus}/>} />
            </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
      </div>
  )}
      </div>
  );
}

export default App;