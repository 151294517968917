import { React, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { money } from '../assets';
import CustomButton from '../components/CustomButton';
import { checkIfImage } from '../utils';
import FormField from '../components/FormField';
import axios from 'axios';
import { loader } from '../assets';
import Filter from 'bad-words';

const CreateProject = ({ setStatus, accessToken }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [repoName, setRepoName] = useState("");
    const [templateType, setTemplateType] = useState("react");
    const filter = new Filter();
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const GITHUB_API_BASE_URL = process.env.REACT_APP_GITHUB_API_BASE_URL;

    const [form, setForm] = useState({
        name: '',
        title: '',
        description: '',
        target: '',
        deadline: '',
        image: ''
    });

    const handleRepoNameChange = (event) => {
        const userInput = event.target.value;
        if (userInput) { 
          const cleanInput = filter.clean(userInput);
          setRepoName(cleanInput);
        } else {
          setRepoName(''); 
        }
      };

    const handleFormFieldChange = (fieldName, e) => {
        setForm({ ...form, [fieldName]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        
        try {
          const response = await axios.post(`${API_BASE_URL}/createRepo`, {
            accessToken,
            repoName,
            templateType,
        },{
            withCredentials: true
          });
          setIsLoading(false);
          navigate('/dashboard');
        } catch (error) {
          console.error("Error creating repository:", error);
          setStatus(`Error creating repository: ${error.message}`);
          setIsLoading(false);
        }
      }

  return (
    <div className="bg-[#1c1c24] flex justify-center items-center flex-col 
    rounded-[10px] sm:p-10 p-4">
    {isLoading && 'Loader...'}
    <div className="flex justify-center items-center p-[16px] 
    sm:min-w-[380px] bg-[#3a3a43] rounded-[10px]">
        <h1 className="font-epilogue font-bold sm:text-[25px] 
        text-[18px] leading-[38px] text-white">Create a project</h1>
    </div>

    <form onSubmit={handleSubmit} className="w-full mt-[65px] flex flex-col gap-[30px]">
        <div className="flex flex-wrap gap-[40px]">
            <FormField 
                labelName="Project Name *"
                placeholder="John Doe"
                inputType="text"
                value={repoName}
                handleChange={(event) => handleRepoNameChange(event)}
            />
        </div>
        <div className="w-full flex justify-start items-center p-4 bg-[#8c6dfd] h-[60px] rounded-[10px]">
          <img src={money} alt="money" className="w-[40px] h-[40px] object-contain"/>
          <h4 className="font-epilogue font-bold text-[10px] sm:text-[16px] text-white ml-[20px] md:my-auto">Currently projects are MERN only. More frameworks will be available soon.</h4>
        </div>
        <div className="flex flex-col font-epilogue justify-center gap-[20px]">
            <div className="flex flex-col gap-[5px]">
            <label className="font-epilogue font-medium text-[14px] 
            leading-[22px] text-[#808191]">Front-End</label>
            <select name="front-end framework" className="py-[15px] sm:px-[25px] px-[15px] 
            outline-none border-[1px] w-full border-[#3a3a43]
            bg-transparent font-epilogue text-white text-[14px] 
            placeholder:text-[#4b5264] rounded-[10px] sm:min-w-[300px]">
                <option value="react">React</option>
            </select>
            </div>
            <div className="flex flex-col gap-[5px]">
            <label className="font-epilogue font-medium text-[14px] 
            leading-[22px] text-[#808191]">Server</label>
            <select name="front-end framework" className="py-[15px] sm:px-[25px] px-[15px] 
            outline-none border-[1px] w-full border-[#3a3a43]
            bg-transparent font-epilogue text-white text-[14px] 
            placeholder:text-[#4b5264] rounded-[10px] sm:min-w-[300px]">
                <option value="express">Express</option>
            </select>
            </div>
            <div className="flex flex-col gap-[5px]">
            <label className="font-epilogue font-medium text-[14px] 
            leading-[22px] text-[#808191]">Back-End</label>
            <select name="front-end framework" className="py-[15px] sm:px-[25px] px-[15px] 
            outline-none border-[1px] w-full border-[#3a3a43]
            bg-transparent font-epilogue text-white text-[14px] 
            placeholder:text-[#4b5264] rounded-[10px] sm:min-w-[300px]">
                <option value="node">Node</option>
            </select>
            </div>
            <div className="flex flex-col gap-[5px]">
            <label className="text-whitefont-epilogue font-medium text-[14px] 
            leading-[22px] text-[#808191]">Database</label>
            <select name="front-end framework" className="py-[15px] sm:px-[25px] px-[15px] 
            outline-none border-[1px] w-full border-[#3a3a43]
            bg-transparent font-epilogue text-white text-[14px] 
            placeholder:text-[#4b5264] rounded-[10px] sm:min-w-[300px]">
                <option value="mongodb">MongoDB</option>
            </select>
            </div>
        </div>
            <div className="flex justify-center items-center mt-[20px]">
            {!isLoading ? (
                <CustomButton
                    btnType="submit"
                    alt="create"
                    title="Create Project"
                    styles="bg-[#1dc071]"
                    /> ) : (
                        <div className="flex flex-col justify-center">
                    <img src={loader} alt="loader" className="w-[100px] 
                    h-[100px] object-contain mx-auto" /><p className="text-green-400 font-epilogue">Creating Github Repo & Adding In Starter Files. This Can Take A Minute Or Two...</p></div>
                    )}
        </div>
    </form>
    </div>
  )};

export default CreateProject