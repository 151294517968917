// useAuth.js
import { useState, useEffect } from 'react';

const useAuth = () => {
    const [loading, setLoading] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);
  
    useEffect(() => {
      const checkToken = async () => {
        const token = await localStorage.getItem('access_token');
        console.log('useAuth token from localStorage:', token);
        if (token !== null) {
          setAuthenticated(true);
        }
        setLoading(false);
      };
  
      checkToken();
    }, []);
  
    return { loading, authenticated };
};

export default useAuth;