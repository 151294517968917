import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import StoryCard from '../components/StoryCard';
import PageCard from '../components/PageCard';
import axios from 'axios';
import Loading from '../components/Loading';
import BuildComplete from '../components/BuildComplete';
import { loader } from '../assets';
import Filter from 'bad-words';

const BuildProject = ({ accessToken }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [ideasAreLoading, setIdeasAreLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const { owner } = useParams();
  const { actualRepoName } = useParams();
  const [sprintsData, setSprintsData] = useState([{ storyCards: ['',], pages: [] }]);
  const [currentSprint, setCurrentSprint] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [editPage, setEditPage] = useState(null);
  const [pageName, setPageName] = useState('');
  const [mockupImage, setMockupImage] = useState(null);
  const [showReview, setShowReview] = useState(false);
  const [whatToBuild, setWhatToBuild] = useState('');
  const [generatedCode, setGeneratedCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const filter = new Filter();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const GITHUB_API_BASE_URL = process.env.REACT_APP_GITHUB_API_BASE_URL;
  const sprintData = sprintsData[currentSprint];
  const storyCards = sprintData.storyCards;
  const pages = sprintData.pages;
  const [tooltip, setTooltip] = useState(false);

  const handleWhatToBuildChange = (event) => {
    const userInput = event.target.value;
    if (userInput) {
      const cleanInput = filter.clean(userInput);
      setWhatToBuild(cleanInput);
    } else {
      setWhatToBuild('');
    }
  };

  const handleCreateClick = async (event) => {
    event.preventDefault();
    setIsLoading(true);
  
    

    try {
      const jobResponse = await axios.post(
        `${API_BASE_URL}/sprints/create`,
        { owner, actualRepoName, sprintsData, whatToBuild },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true,
        }
      );
        
      const jobId = jobResponse.data.jobId;
      const pollJobStatus = async (jobId) => {
        const POLL_INTERVAL = 5000; // Adjust the interval as needed (in milliseconds)
        
        const checkJobStatus = async () => {
          const response = await axios.get(
            `${API_BASE_URL}/sprints/job-status/${jobId}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              withCredentials: true,
            }
          );
      
          const { status, data } = response;
      
          if (status === 200) {
            return data.generatedCode;
          } else if (status === 202) {
            return null;
          } else {
            throw new Error('Unexpected status while polling job status');
          }
        };
      
        return new Promise(async (resolve, reject) => {
          const poll = async () => {
            try {
              const result = await checkJobStatus();
              if (result !== null) {
                resolve(result);
              } else {
                setTimeout(poll, POLL_INTERVAL);
              }
            } catch (error) {
              reject(error);
            }
          };
      
          poll();
        });
      }
      const updateAppJsFile = async (owner, actualRepoName, accessToken, newCode) => {
        try {
          const fileResponse = await axios.get(
            `${API_BASE_URL}/api/github/repos/${owner}/${actualRepoName}/contents/client/src/App.js`,
            {
              headers: {
                Authorization: `token ${accessToken}`,
              },
              withCredentials: true,
            }
          );
      
          const fileData = fileResponse.data;
            console.log('file data')
            console.log(fileData)
          // Decode the file content from Base64
          const decodedContent = atob(fileData.content);
      
          // Modify the file content as needed
          const updatedContent = newCode;
          console.log('NEW CODE')
            console.log(newCode)
          // Encode the updated file content in Base64
          const encodedUpdatedContent = btoa(updatedContent);
          console.log(encodedUpdatedContent)
          const updateResponse = await axios.put(
            `${API_BASE_URL}/api/github/repos/${owner}/${actualRepoName}/contents/client/src/App.js`,
            {
              message: "Update App.js with generated code",
              content: encodedUpdatedContent,
              sha: fileData.sha,
            },
            {
              headers: {
                Authorization: `token ${accessToken}`,
              },
              withCredentials: true,
            }
          );
        } catch (error) {
          console.error('Error updating App.js file:', error.message);
        }
      };

      const generatedCode = await pollJobStatus(jobId);
      console.log('generated code:', generatedCode);
  
      await updateAppJsFile(owner, actualRepoName, accessToken, generatedCode);
      setGeneratedCode(generatedCode);
      setIsLoading(false);
      setIsComplete(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('Something went wrong');
      }
    }
  };
  
  
  const generateIdeas = async () => {
    setIdeasAreLoading(true);
    try {
    let generatedIdeas = await axios.post(
      `${API_BASE_URL}/sprints/generateIdeas`,
      { owner, actualRepoName, sprintsData, whatToBuild },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true
      }
    );

    updateSprintData({ storyCards: generatedIdeas.data, pages });

  } catch (error) {
    console.error('Error generating ideas:', error);
  }
  setIdeasAreLoading(false);
};

useEffect(() => {

}, [sprintsData]);

  const handleExitClick = async (event) => {
    event.preventDefault()
    setIsComplete(false)
    navigate('/dashboard')
  }

  const updateSprintData = (updatedData) => {
    const updatedSprintsData = [...sprintsData];
    updatedSprintsData[currentSprint] = updatedData;
    setSprintsData(updatedSprintsData);
  };

  const handleStoryCardChange = (event, index) => {
    event.preventDefault();
    const newStoryCards = [...storyCards];
    newStoryCards[index] = event.target.value;
    updateSprintData({ storyCards: newStoryCards, pages });
  };

  const handleRemoveStoryCard = (event, index) => {
    event.preventDefault();
    updateSprintData({ storyCards: storyCards.filter((_, i) => i !== index), pages });
  };

  const handleAddStoryCard = (event) => {
    event.preventDefault();
    updateSprintData({ storyCards: [...storyCards, ''], pages });
  };

  return (
    <div className="bg-[#1c1c24] flex justify-center items-center flex-col rounded-[10px] sm:p-10 p-4">
    { !isLoading && !isComplete && (
        <>    
    <div className="flex justify-center items-center p-[16px] 
    sm:min-w-[380px] bg-[#3a3a43] rounded-[10px]">
        <div className="flex flex-col items-center">
        <h1 className="font-epilogue font-bold sm:text-[25px] 
        text-[18px] leading-[38px] text-white">
            Build project</h1>
            <p className="text-white font-epilogue">{actualRepoName}</p>
            </div>
    </div>
    <form className="w-full mt-[30px] flex flex-col gap-[30px]">
        <div className="flex flex-wrap gap-[40px]">
            <label className="flex-1 w-full flex flex-col">
            <span className="font-epilogue font-medium text-[14px] 
            leading-[22px] text-[#808191] mb-[10px]">What Do You Want To Build? *</span>
            <input 
                className="py-[15px] sm:px-[25px] px-[15px] 
                outline-none border-[1px] border-[#3a3a43]
                bg-transparent font-epilogue text-white text-[14px] 
                placeholder:text-[#4b5264] rounded-[10px] sm:min-w-[300px]"
                placeholder="Write a short sentence describing your web app..."
                type="text"
                value={whatToBuild}
                onChange={(e) => handleWhatToBuildChange(e)}
            />
            </label>
            </div>
            <div>
                <div className="flex flex-wrap gap-[15px] items-center">
            <span className="font-epilogue font-medium text-[14px] 
            leading-[22px] text-[#808191] mb-[10px]">What exact functionalities would you like? *</span>
            <button type="button" alt="button" onClick={generateIdeas} className="p-[20px] 
            text-sm font-epilogue bg-purple-400 text-white rounded-[10px] py-2 px-4">
            <i class="fas fa-magic mr-3">

            </i>Generate functionality ideas</button>
            {tooltip ? <><div class="z-0 pl-2 flex relative text-sm 
            pr-2 border-[1px] rounded-lg 
        bg-gray-800 text-purple-400
         border-purple-800 sm:ml-2 sm:mt-0" role="alert">
            <svg aria-hidden="true" 
            class="flex-shrink-0 inline w-5 h-5 mr-3" 
            fill="currentColor" viewBox="0 0 20 20" 
            xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M18 10a8 
              8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <div>
                <span class="font-medium">Coming soon.</span>
                <div
                class="absolute -z-10 sm:left-[3px] sm:top-[-5px] 
                sm:transform sm:-translate-x-1/2 
                sm:translate-y-1/2 sm:rotate-45 w-4 h-4
                 bg-gray-800 sm:border-l sm:border-b border-purple-800"
            ></div>
            </div>
            </div></> : <></>}
            
            </div>
            { ideasAreLoading ? 
            
            <div className="flex flex-col mt-4"><p className="text-green-600 font-epilogue text-sm">Generating user stories. This can take a minute...</p><img src={loader} alt="loader" className="w-[100px] 
            h-[100px] object-contain mt-[-20px]" /></div> :
            <><div className="flex flex-col">{storyCards.map((storyCard, index) => (
                <StoryCard  
                value={storyCard} 
                handleChange={(event) => handleStoryCardChange(event, index)}
                handleDelete={(event) => handleRemoveStoryCard(event, index)}
                />
            ))}
            </div>
            <button type="button" alt="button" onClick={handleAddStoryCard} className="bg-[#4acd8d] rounded-[10px] text-white px-4 py-2 font-epilogue text-sm mt-2">Add Story Card<i class="fas fa-plus ml-2"></i></button>
            </>}
            </div>
            <div className="flex flex-col justify-center">
            <div className="flex flex-wrap gap-[15px] items-center">
            <span className="font-epilogue font-medium text-[14px] 
            leading-[22px] text-[#808191] mb-[10px]">Do you have UI/UX mockups prepared? *</span>
            </div>
                <PageCard />
            </div>
            <div className="flex flex-col justify-center items-center">
            {errorMessage && (
                <div className="text-red-500 mt-2">{errorMessage}</div>
              )}
                <button onClick={(event) => handleCreateClick(event)}  alt="submit" type="submit" className="font-epilogue font-semibold text-[16px] 
                leading-[26px] text-white sm:min-h-[52px] py-2 px-4 rounded-[10px] w-full md:max-w-[200px] bg-[#1dc071]">
                    Build
                </button>
                </div>
        </form>
        </>)}
        { isLoading && (
            <Loading />)
        }
        { isComplete && (
            <BuildComplete actualRepoName={actualRepoName} owner={owner} handleExitClick={handleExitClick}/>
        )}
    </div>
  )
}

export default BuildProject