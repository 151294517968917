// PrivateRoutes.js
import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import useAuth from './useAuth';

const PrivateRoutes = () => {
  const { loading, authenticated } = useAuth();
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return authenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;