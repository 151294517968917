import React, { useState, useEffect } from 'react'
import DisplayProjects from '../components/DisplayProjects';

const Home = ({ accessToken, userIdProp, updateRepositories }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [campaigns, setCampaigns] = useState([]);

  return (
    <div><DisplayProjects 
      title="All Projects"
      isLoading={isLoading}
      campaigns={campaigns}
      accessToken={accessToken}
      userIdProp={userIdProp}
      updateRepositories={updateRepositories}
      /></div>
  )
}

export default Home