import React from 'react'

const StoryCard = ({ value, handleChange, handleDelete }) => {
  return (
    <div className="w-full rounded-[15px] bg-[#1c1c24]">

    <div className="flex flex-col mt-[15px] bg-gray-800 p-3 md:max-w-[800px] rounded-[15px]">
    <span className="font-epilogue font-medium text-[14px] 
            leading-[22px] text-[#808191] mb-[10px] text-opacity-50">As a user I want to...</span>
        <div className="flex flex-row gap-[15px]">
        <input className="py-[15px] sm:px-[25px] px-[15px] 
            outline-none border-[1px] border-[#3a3a43]
            bg-transparent font-epilogue text-white text-[14px] 
            placeholder:text-[#4b5264] rounded-[10px] w-full" value={value} onChange={handleChange}/>
    <button type="button" alt="button" className="text-sm font-epilogue
            bg-red-400 cursor-pointer text-white rounded-[10px] px-4 py-2 
            " onClick={handleDelete}><i class="fas fa-trash"></i></button>
    </div>
</div>
</div>
  )
}

export default StoryCard