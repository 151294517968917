import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom"

import { logo } from '../assets';
import { navlinks } from '../constants';

const Icon = ({ styles, name, imgUrl, isActive, disabled, handleClick }) => (
  <div className={`w-[48px] h-[48px] rounded-[10px] 
  ${isActive && isActive === name && 'bg-[#2c2f32]'} 
  flex justify-center items-center ${!disabled && 
  'cursor-pointer'} ${styles}`} onClick={handleClick}>
    {!isActive ? (
      <img src={imgUrl} alt="workbench_logo" className="w-1/2 h-1/2" />
    ) : (
      <img src={imgUrl} alt="workbench_logo" className={`w-1/2 h-1/2 ${isActive !== name && 'grayscale'}`} />
    )
  }
  </div>
  )

const Sidebar = ({ handleSignOut }) => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState('dashboard')
  const [showModal, setShowModal] = React.useState(false);

return (
  <>
<div className="flex justify-between items-center flex-col sticky top-5 h-[93vh]">
<Link to="/dashboard" className="z-50 relative">
  <div className="flex items-center justify-center h-[60px] w-[60px] bg-[#1c1c24] rounded-[10px] overflow-hidden">
    <img src={logo} className="absolute w-[60px] h-[60px] mb-[6px] ml-[2px] object-position" />
  </div>
</Link>
<div className="flex-1 flex flex-col justify-between items-center bg-[#1c1c24] rounded-[20px] w-[76px] py-4 mt-12">
  <div className="flex flex-col justify-center items-center gap-3">
    {navlinks.map((link) => (
      <Icon 
        key={link.name}
        {...link}
        isActive={isActive}
        handleClick={() => {
          if (link.link === "/logout") {
            handleSignOut(() => {
              navigate('/login');
            });
          }
          else if (link.link === "/billing") {
            setShowModal(true)
          }
          else if (link.disabled === false) {
            setIsActive(link.name);
            navigate(link.link)
          }
        }}
      />
    ))}
  </div>
  
</div>
</div>
{showModal ? (
        <>
          <div
            className="justify-center font-epilogue items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">

              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-700 outline-none focus:outline-none">

                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold text-slate-200 ">
                    Story Card Credits
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-white opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-white opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>

                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-300 text-lg leading-relaxed">
                    In order to make Workbench sustainable, we have created 'Story Card Credits'. These are what we use to fund the software build process. All users start with 100 credits. If you need to top-up or get more please, request them using the button below.
                  </p>
                </div>

                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={(e) => {
                      window.open("mailto:support@tryworkbench.com?subject=Request%20For%20Story%20Card%20Credits&body=Requesting%20100%20new%20story%20card%20credits.");
                      e.preventDefault();
                  }}
                  >
                    Request
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
</>
  );
};

export default Sidebar;
