import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';

const Callback = (props, onAuthenticated) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const accessToken = searchParams.get('access_token');
    
    if (accessToken) {
      props.handleAccessToken(accessToken);
      navigate('/dashboard');
    } else {
      console.error('Access token not found in URL');
      navigate('/');
    }
  }, [props, navigate, searchParams]);

  return <div>Processing authentication...</div>;
};

export default Callback;
