import { React, useState } from 'react'

const PageCard = () => {
  const [isDiplaying, setIsDisplaying] = useState(false);

  const handleClick = () => {
    setIsDisplaying((prevIsDisplaying) => !prevIsDisplaying);
    }

  return (
    <div className="flex flex-wrap items-center">
        <div class="flex w-full flex-col items-center font-epilogue justify-center sm:w-1/3">
            <label for="dropzone-file" className="flex flex-col items-center 
            justify-center w-full h-64 border-2 
            border-dashed rounded-lg cursor-pointer
            hover:bg-bray-800 bg-gray-700
             border-gray-600 hover:border-gray-500 
             hover:bg-gray-600" onClick={handleClick}>
                <div className="flex flex-col items-center justify-center 
                pt-5 pb-6">
                    <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                    <p className="mb-2 text-sm text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                    <p className="text-xs text-gray-400">
                        SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                        <p className="text-xs text-gray-400">filename will become page name</p>
                </div>
                <input disabled id="dropzone-file" type="file" className="hidden"/>
            </label>
        </div>
        {isDiplaying ?
        <div class="flex relative p-4 mb-4 text-sm sm:w-1/3 border-[1px] rounded-lg 
        bg-gray-800 text-purple-400
         border-purple-800 sm:ml-8 mt-4 sm:mt-0" role="alert">
            <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <div>
                <span class="font-medium">UI/UX image upload will be become available when multi-modal data is supported by our AI engine.</span>
                <div
                class="absolute sm:left-0 sm:top-[15px] 
                sm:transform sm:-translate-x-1/2 
                sm:translate-y-1/2 sm:rotate-45 w-4 h-4
                 bg-gray-800 sm:border-l sm:border-b border-purple-800"
            ></div>
            </div>
            </div>
           : <></>}
    </div>
  )
}

export default PageCard