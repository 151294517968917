import React from 'react';
import { loaderCubes } from '../assets';

const Loading = () => {
  return (
    <div className="text-center mt-4 items-center">
        <h2 className="pt-4 text-green-400 font-epilogue">Development In Progress...</h2>
        <img src={loaderCubes} alt="loader" className="w-[200px] 
                h-[200px] mt-24 mb-24  object-contain mx-auto" />
                <h4 className="pt-4 text-green-400 font-epilogue">This can take a couple of minutes, but it's going to be worth it!</h4>
    </div>
  )
}

export default Loading