import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import CustomButton from './CustomButton';
import { logo, menu, search, thirdweb } from '../assets';
import { navlinks } from '../constants';

const Navbar = ({ accessToken, userIdProp, setUserIdProp, handleSignOut }) => {
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState("dashboard");
    const [toggleDrawer, setToggleDrawer] = useState(false);
    const [credits, setCredits] = useState(null);
    const [socket, setSocket] = useState(null);
    const address ='0xabc';
    const [loading, setLoading] = useState(true);
    const [userAvatar, setUserAvatar] = useState(null);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const WS_URL = process.env.REACT_APP_WS_URL;
    const GITHUB_API_BASE_URL = process.env.REACT_APP_GITHUB_API_BASE_URL;

    const fetchUserData = useCallback(async () => {
      if (accessToken) {
        console.log('Access token:', accessToken);
        try {
          const response = await axios.get(`${API_BASE_URL}/github/user`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`, // Add the access token here
            },
            withCredentials: true,
          });
          const data = response.data;
          setUserIdProp(data.login);
          setUserAvatar(data.avatar_url);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching user data:', error.message);
        }
      }
    }, [accessToken, setUserIdProp]);
      
      useEffect(() => {
        fetchUserData();
      }, [fetchUserData]);

      useEffect(() => {
        const newSocket = new WebSocket(`wss://${WS_URL}`);
        setSocket(newSocket);
        return () => {
          newSocket.close();
        };
      }, []);

      useEffect(() => {
        if (socket) {
          socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.userId === userIdProp) {
              setCredits(data.credits);
            }
          };
        }
      }, [socket, userIdProp]);

      useEffect(() => {
        if (userIdProp !== '' && !loading) {
          const fetchCredits = async () => {
            try {
              const userId = userIdProp;
              const response = await axios.get(`${API_BASE_URL}/sprints/api/credits/${userId}`, {
                withCredentials: true,
              });
              setCredits(response.data.credits);
            } catch (error) {
              console.error("Error fetching credits:", error.message);
            }
          };
          fetchCredits();
        }
      }, [userIdProp, loading]);
    
    const calculateCreditPercentage = () => {
        return (credits / 100) * 100;
    };


  return (
    <div className="flex md:flex-row flex-col-reverse justify-between mb-[10px] sm:mb-12 gap-6">
        <div className="lg:flex-1 flex flex-row sm:h-[52px] max-w-[500px] 
        py-2 pl-4 pr-2">
            {credits !== null && (
            <div className="sm:flex hidden ml-12 items-center mb-4">
        <div className="relative w-64 h-4 bg-gray-300 rounded">
          <div
            className="absolute left-0 top-0 h-4 bg-green-500 rounded"
            style={{ width: `${calculateCreditPercentage()}%` }}
          ></div>
        </div>
        <span className="ml-2 text-white font-epilogue font-light my-auto">{`Story Card Credits: ${credits}/100`}</span>
      </div>)}
        </div> 
        <div className="sm:flex hidden flex-row justify-end gap-4">
            <CustomButton
                btnType="button"
                title={address ? 'Create a project' : 'Connect'}
                styles={address ? 'bg-[#1dc071]' : 'bg-[#8c6dfd]'}
                handleClick={() => {
                    if(address) navigate('create-project')
                    else console.log('')
                }}
            />

            <Link to='/'>
            <div className="w-[52px] h-[52px] rounded-full bg-[#2c2f32] flex justify-center items-center cursor-pointer">
              <img src={userAvatar || "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/A_black_image.jpg/800px-A_black_image.jpg?20201103073518"} alt="user" className="
              w-full h-full object-cover rounded-full"/>
            </div>
            </Link>
        </div>

      
        <div className="sm:hidden flex justify-between items-center relative">
        <Link to='/dashboard'>
            <div className="w-[60px] h-[60px] rounded-[10px] bg-[#2c2f32] 
            flex justify-center items-center cursor-pointer">
                <img src={logo} alt="user" className="w-[80%] h-[80%] object-contain"/>
            </div>
        </Link>
            <img 
                src={menu}
                alt="menu"
                className="w-[34px] h-[34px] object-contain cursor-pointer"
                onClick={() => setToggleDrawer(!toggleDrawer)}
                />

                <div className={`absolute top-[60px] right-0 left-0 bg-[#1c1c24] 
                z-10 shadow-secondary py-4 ${!toggleDrawer ? 
                '-translate-y-[100vh]' : 'translate-y-0'} 
                transition-all duration-700`}>
                    <ul className="mb-4">
                        {navlinks.map((link) => (
                            <li
                                key={link.name}
                                className={`flex p-4 ${isActive === link.name && 'bg-[#3a3a43]'}`}
                                onClick={() => {
                                  if (link.link === "/logout") {
                                    handleSignOut(() => {
                                      navigate('/login');
                                    });
                                  }
                                  else if (link.link === "/billing") {
                                    setShowModal(true)
                                    setToggleDrawer(false)
                                  }
                                  else if (link.disabled === false) {
                                    setIsActive(link.name);
                                    navigate(link.link)
                                    setToggleDrawer(false)
                                  }
                                }}
                                >
                                <img
                                    src={link.imgUrl}
                                    alt={link.name}
                                    className={`w-[24px] h-[24px] object-contain ${isActive === link.name ? 'grayscale-0' : 'grayscale'}`}
                                />
                                <p className={`ml-[20px] font-epilogue
                                font-semibold text-[14px] ${isActive ===
                                link.name ? 'text-[#1dc071]' : 'text-[#808191]'}`}>
                                    {link.name}
                                </p>
                                </li>
                        ))}
                    </ul>

                    <div className="flex mx-4">
                    <CustomButton
                        btnType="button"
                        title={address ? 'Create a project' : 'Connect'}
                        styles={address ? 'bg-[#1dc071]' : 'bg-[#8c6dfd]'}
                        handleClick={() => {
                            if(address) navigate('create-project')
                            else console.log('')
                        }}
                    />
                    </div>
                </div>
        </div>
    </div>
  )
}

export default Navbar