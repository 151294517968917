import React from 'react'
import { useNavigate } from 'react-router-dom';
import { tagType, thirdweb } from '../assets';
import { daysLeft } from '../utils';
import CustomButton from './CustomButton';

const images = ['https://images.nightcafe.studio/jobs/Dmxw9oLmenuZSX7rRiHU/Dmxw9oLmenuZSX7rRiHU.jpg?tr=w-1600,c-at_max',
'https://images.nightcafe.studio/jobs/fIB88IC27dcopoJEEiEQ/fIB88IC27dcopoJEEiEQ--4--1bkrb_2x.jpg?tr=w-1600,c-at_max',
'https://images.nightcafe.studio/jobs/OcEfMKd6fmOPTHCy4DIP/OcEfMKd6fmOPTHCy4DIP.jpg?tr=w-1600,c-at_max',
'https://images.nightcafe.studio/jobs/mBVwHg7dCaPfNncBbaro/mBVwHg7dCaPfNncBbaro--3--eavnq_2x.jpg?tr=w-1600,c-at_max',
'https://images.nightcafe.studio/jobs/yk91kx7YvEbnLkkA2Cu0/iDGze0LH6TdyWVwFTmn9--4--f2xy2_4x_3.125x.jpg?tr=w-1600,c-at_max',
'https://images.nightcafe.studio/jobs/03mTGnjWnyfkMw4lPkzv/03mTGnjWnyfkMw4lPkzv--3--stvzz.jpg?tr=w-1600,c-at_max',
'https://images.nightcafe.studio/jobs/MG5TomXz400LX2849rqa/MG5TomXz400LX2849rqa--1--6vhr1.jpg?tr=w-1600,c-at_max',
'https://images.nightcafe.studio/jobs/eTadAmpw0JKlSauPoPuA/eTadAmpw0JKlSauPoPuA--4--m7yhr.jpg?tr=w-1600,c-at_max',
'https://images.nightcafe.studio/jobs/K39sgiqHTiEbutfI3mJE/K39sgiqHTiEbutfI3mJE--6--dtwfl.jpg?tr=w-1600,c-at_max',
'https://images.nightcafe.studio/jobs/KzGYEJ5KamjTqKKhWB8V/KzGYEJ5KamjTqKKhWB8V--1--7dqxp.jpg?tr=w-1600,c-at_max',
'https://images.nightcafe.studio/jobs/RsDt0lrLYFFo93NnOwL6/RsDt0lrLYFFo93NnOwL6--3--ylnp6_6x.jpg?tr=w-1600,c-at_max',
'https://images.nightcafe.studio/jobs/FL1vUdIjgB5uCjlv85mT/FL1vUdIjgB5uCjlv85mT--1--2bmoq.jpg?tr=w-1600,c-at_max',
'https://images.nightcafe.studio/jobs/n4S0SisoVpiTsUhF3iem/n4S0SisoVpiTsUhF3iem--1--fhjkh_8x.jpg?tr=w-1600,c-at_max',
'https://images.nightcafe.studio/jobs/7SerTBDqaBQjXhvAHKzp/7SerTBDqaBQjXhvAHKzp--1--nh5oi.jpg?tr=w-1600,c-at_max',
'https://images.nightcafe.studio/jobs/nqxLapspdsDJzI5jRuPx/nqxLapspdsDJzI5jRuPx--1--uwwgr.jpg?tr=w-1600,c-at_max',
'https://images.nightcafe.studio/jobs/UaHa372siGNqy9qaKcAk/UaHa372siGNqy9qaKcAk--1--1hr58_2x.jpg?tr=w-1600,c-at_max',
'https://images.nightcafe.studio/jobs/LVGzbx5fGUqycbW1kSJj/LVGzbx5fGUqycbW1kSJj--4--ml7o7.jpg?tr=w-1600,c-at_max',
'https://images.nightcafe.studio/jobs/Wdq7ZOw3jPr7WIEWwRFJ/Wdq7ZOw3jPr7WIEWwRFJ--1--e5fb7.jpg?tr=w-1600,c-at_max',
]

const ProjectCard = ({ deadline, index, handleClick, repo }) => {
    const navigate = useNavigate();
    const remainingDays = daysLeft(deadline);
    const image = images[index % images.length];

  const handleBuildProject = () => {
    navigate(`/build-project/${decodeURIComponent(repo.full_name)}`);
    };

    return (
    <div className="sm:w-[288px] w-full rounded-[15px] bg-[#1c1c24]
     " onClick={handleClick} >
        <img src={image} alt="image" className="w-full
        h-[158px] object-cover rounded-[15px]" />

        <div className="flex flex-col p-4 ">
            <div className="flex items-center mb-[18px]">
                <img src={tagType} alt="tag" className="w-[17px] h-[17px] object-contain" />
                <p className="ml-[12px] mt-[17px] font-epilogue 
                font-medium text-[12px] text-[#808191]">Web Application</p>
            </div>

            <div className="block">
                <h3 className="font-epilogue font-semibold 
                text-[16px] text-white text-left leading-[26px]
                 truncate">{repo.name}</h3>
                <p className="mt-[5px] font-epilogue font-normal
                 text-[#808191] text-left leading-[18px] truncate">
                    {repo.description}</p>
            </div>
            <div className="flex flex-col items-center justify-center 
            mt-[4px]">
            <CustomButton
                    btnType="button"
                    alt="Build"
                    title="Build project"
                    styles="bg-[#1dc071] w-full"
                    handleClick={handleBuildProject}
                    />
            </div>
        </div>
    </div>
  )
}

export default ProjectCard