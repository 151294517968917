import React from 'react'
import { useNavigate } from 'react-router-dom';

const BuildComplete = ({ actualRepoName, owner, handleExitClick }) => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col gap-[15px] items-center font-epilogue">
    <div className="flex justify-center items-center p-[16px] 
    sm:min-w-[380px] bg-[#3a3a43] rounded-[10px]">
        <div className="flex flex-col items-center">
        <h1 className="font-epilogue font-bold sm:text-[25px] 
        text-[18px] leading-[38px] text-white">
            Build Complete</h1>
            </div>
    </div>
    <p className="text-white">Congratulations! Your project has been built.</p>
    <div class="pl-2 relative text-sm 
            pr-2 border-[1px] rounded-lg 
        bg-gray-800 text-purple-400
         border-purple-800 sm:ml-2 sm:mt-0" role="alert">
    You can access your code repository <a href={`https://github.com/${owner}/${actualRepoName}`} className="text-purple-400
     hover:text-purple-400">here</a>
</div>
    <p className="text-white">To see your project in action, follow the steps below.</p>
    <div className="bg-black w-full sm:min-w-[300px] rounded-md overflow-auto mt-4">
        <div className="flex items-center justify-between p-2 bg-gray-800 rounded-t-md">
          <div className="flex space-x-1.5">
            <div className="w-3 h-3 rounded-full bg-red-500"></div>
            <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
            <div className="w-3 h-3 rounded-full bg-green-500"></div>
          </div>
        </div>
        <div className="flex flex-col gap-[2px] p-4">
        <div class="flex">
          <span class="text-green-400">computer:~$</span>
          <p class="flex-1 typing items-center pl-2 pr-4 text-white">
              git clone https://github.com/{owner}/{actualRepoName}
              <br />
          </p>
      </div>
      <div class="flex">
          <span class="text-green-400">computer:~$</span>
          <p class="flex-1 typing items-center pl-2 pr-4 text-white">
              cd {actualRepoName}
              <br />
          </p>
      </div>
      <div class="flex">
          <span class="text-green-400">computer:~$</span>
          <p class="flex-1 typing items-center pl-2 pr-4 text-white">
              npm i react-scripts web-vitals react-dom
              <br />
          </p>
      </div>
      <div class="flex">
          <span class="text-green-400">computer:~$</span>
          <p class="flex-1 typing items-center pl-2 pr-4 text-white">
              npm install
              <br />
          </p>
      </div>
            <div class="flex">
            <span class="text-green-400">computer:~$</span>
            <p class="flex-1 typing items-center pl-2 pr-4 text-white">
              npm start
                <br />
            </p>
        </div>
        </div>
      </div>
    <button onClick={(event) => handleExitClick(event)}  alt="submit" type="button" className="font-epilogue font-semibold text-[16px] 
    leading-[26px] mt-12 text-white min-h-[52px] px-4 rounded-[10px] w-full md:max-w-[200px] bg-[#1dc071]">
        Back To Dashboard
    </button>
    </div>
  )
}

export default BuildComplete